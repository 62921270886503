import React, { ReactNode } from 'react';
import { ProductLogoOptions, ProductLogo } from '../assets/ProductLogo';
import parse from "html-react-parser"
import classNames from 'classnames';
import { StaticImageData } from 'next/image';
import { ProductLogoMachineNames, ProductLogoNames } from '@/types';

interface GradientHeaderProps {
    title: string;
    summary?: string | ReactNode;
    image?: string;
    eyebrow?: string;
    isAddOn?: boolean;
    productLogo?: ProductLogoMachineNames | ProductLogoNames | null | StaticImageData;
    children?: ReactNode
    className?: string
}

export const bgMaps:Record<keyof typeof ProductLogoOptions, string> = {
    cloud_platform: 'bg-gradient-to-r from-navy-600 to-blue-600',
    cms: 'bg-gradient-to-r from-navy-600 to-blue-600',
    site_factory: 'bg-gradient-to-r from-navy-600 to-blue-600',
    cdp: 'bg-gradient-to-r from-navy-600 to-pink-600',
    dam: 'bg-gradient-to-r from-navy-600 to-pink-600',
    campaign_studio: 'bg-gradient-to-r from-navy-600 to-pink-600',
    drupal_cloud_addon: 'docs-bg-dc-light',
    marketing_cloud_addon: 'docs-bg-mc-light',
    monsido: 'bg-gradient-to-r from-navy-600 to-blue-600',
    convert: 'bg-gradient-to-r from-navy-600 to-teal-600',
    seo: 'bg-gradient-to-r from-navy-600 to-teal-600',

    // Deprecated
    CloudPlatform: 'bg-gradient-to-r from-navy-600 to-blue-600',
    Cms: 'bg-gradient-to-r from-navy-600 to-blue-600',
    SiteFactory: 'bg-gradient-to-r from-navy-600 to-blue-600',
    Cdp: 'bg-gradient-to-r from-navy-600 to-pink-600',
    Dam: 'bg-gradient-to-r from-navy-600 to-pink-600',
    CampaignStudio: 'bg-gradient-to-r from-navy-600 to-pink-600',
    DrupalCloudAddOn: '',
    MarketingCloudAddOn: '',
    Monsido: 'bg-gradient-to-r from-navy-600 to-blue-600',
    Convert: 'bg-gradient-to-r from-navy-600 to-teal-600',
    Seo: 'bg-gradient-to-r from-navy-600 to-teal-600'
}

export const bgSvgMap:Record<keyof typeof ProductLogoOptions, string> = {
    cloud_platform: 'docs-bg-dc',
    cms: 'docs-bg-dc',
    site_factory: 'docs-bg-dc',
    cdp: 'docs-bg-mc',
    dam: 'docs-bg-mc',
    campaign_studio: 'docs-bg-mc',
    drupal_cloud_addon: 'docs-bg-dc-light',
    marketing_cloud_addon: 'docs-bg-mc-light',
    monsido: 'docs-bg-dc',
    convert: 'docs-bg-oc',
    seo: 'docs-bg-oc',

    // Deprecated
    CloudPlatform: 'docs-bg-dc',
    Cms: 'docs-bg-dc',
    SiteFactory: 'docs-bg-dc',
    Cdp: 'docs-bg-mc',
    Dam: 'docs-bg-mc',
    CampaignStudio: 'docs-bg-mc',
    DrupalCloudAddOn: 'docs-bg-dc-light',
    MarketingCloudAddOn: 'docs-bg-mc-light',
    Monsido: 'docs-bg-dc',
    Convert: 'docs-bg-oc',
    Seo: 'docs-bg-oc'
}

/**
 * Primary UI component for user interaction
 */
export const GradientHeader = ({
    title,
    eyebrow,
    summary,
    isAddOn = false,
    productLogo = null,
    className = '',
    children = <></>
  }: GradientHeaderProps) => {

    // Organise products into marketing and drupal clouds.
    const clouds:{
        drupal: (keyof typeof ProductLogoOptions)[]
        marketing: (keyof typeof ProductLogoOptions)[]
    } = {
        drupal: ['CloudPlatform', 'Cms', 'SiteFactory'],
        marketing: ['Cdp', 'CampaignStudio', 'Dam']
    }

    if (isAddOn && typeof productLogo == "string") {
        if (clouds.drupal.indexOf(productLogo) != -1 ) {
            productLogo = 'drupal_cloud_addon'
        }
        if (clouds.marketing.indexOf(productLogo) != -1 ) {
            productLogo = 'marketing_cloud_addon'
        }
    }

    let style:('Color' | 'White') = (productLogo == 'drupal_cloud_addon') ? 'Color' : 'White'
    let textColor:('navy-600' | 'white') = (productLogo == 'drupal_cloud_addon') ? 'navy-600' : 'white'

    let bgSvg = 'docs-bg-dc';
    if (productLogo === null) {
        bgSvg = 'bg-gradient-to-r from-gray-700 to-gray-500'
    } else if (typeof productLogo == "string") {
        bgSvg = classNames(bgMaps[productLogo], bgSvgMap[productLogo])
    }
    let logoEl;

    if (isAddOn) {
        style = 'Color';
        textColor = 'navy-600'
    }

    if (productLogo === null || typeof productLogo == "string") {
        logoEl =  (<div className='basis-1/3 py-10 min-h-[252px] product-logo hidden md:block'>
            {productLogo && <ProductLogo className="w-32" name={productLogo as ProductLogoNames} logoStyle={style} />}
        </div>);
    }
    else {
        logoEl =  (<div className='basis-1/3 py-10 min-h-[252px] hidden md:block'>
           <img alt="404 Eevee Cat" {...productLogo} className='h-full max-h-64 w-max' />
        </div>);
    }

    return (
      <div className={classNames(bgSvg, 'docs-bg-header', className)} >
            <div className={`container mx-auto flex place-items-center`}>
                <div className='lg:basis-2/3 px-5 py-10'>
                    <div aria-hidden={true}>
                        { eyebrow ? (
                            <span className={`meta-eyebrow text-${textColor} text-lg font-display`}>{eyebrow}</span>
                        ) : ( 
                            <></> 
                        )}

                        <div className={`meta-header text-${textColor} text-5xl/[1.3] font-display font-bold mb-2`}>{parse(title)}</div>

                        { typeof summary == "string" ? (
                            <div dangerouslySetInnerHTML={ {__html: summary} } className={`max-w-2xl text-${textColor} font-thin text-xl mb-4`} />
                        ) : summary && (
                            <div className={`max-w-2xl text-${textColor} font-thin text-xl mb-4`}>{summary}</div>
                        )}
                    </div>
                    {children}
                </div>
                {logoEl}
            </div>
      </div>
    );
  };
