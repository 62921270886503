"use client";

import { GradientHeader } from "@/components/block/GradientHeader";
import React, { useEffect } from "react";
import { signIn } from "next-auth/react";
import { useUserInformation } from "@/contexts/UserProvider";
import { useSearchParams } from "next/navigation";

const LoginPage = () => {
  const { isLoggedIn } = useUserInformation();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!isLoggedIn) {
      signIn("okta");
    } else {
      const location = searchParams.get("location");
      if (location) {
        window.location.href = location;
      } else {
        window.location.href = '/';
      }
    }
  }, [isLoggedIn, searchParams]);

  return (
    <>
      <GradientHeader title="Authenticating..." eyebrow="We are getting you to your destination" />
    </>
  );
};

export default LoginPage;
